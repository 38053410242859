@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(./fonts/Poppins-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins Bold";
  src: local("Poppins Bold"), url(./fonts/Poppins-Bold.ttf) format("truetype");
}

body {
  margin: 0;
  font-family: "Poppins", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: background-color 1.5s ease;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bold {
  font-family: "Poppins Bold", serif;
}

.App-header {
}

.video-component {
  position: relative;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
}

.text-container {
  z-index: 1;
}

.icons-container {
  z-index: 1;
  display: flex;
  gap: 10px;
}

.icon {
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.App-header-open {
  background-color: transparent !important;
  background-image: none !important;
  color: inherit !important;
  box-shadow: none !important;
}

.list {
  font-weight: 400;
  font-size: 2.125rem;
  line-height: 1.235;
  letter-spacing: 0.00735em;
  text-align: left;
}

.normal {
  font-weight: initial;
  font-size: initial;
  line-height: initial;
  letter-spacing: initial;
  text-align: initial;
}

@media (max-width: 480px) {
  .hidden-xs {
    display: none;
  }
  main {
    padding: 0 !important;
  }
}

@media (max-width: 576px) {
  .hidden-xs {
    display: none;
  }
}

@media (min-width: 577px) {
  .show-only-xs {
    display: none;
  }
}
